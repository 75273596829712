export type LinksType = {
  title: string
  link: string
  blank?: boolean
  iconName?: string
  iconComp?: JSX.Element
  onlyShowLogin?: boolean
  iconUrl?: string
}

export type NestedNavigationLinksType = {
  title: string
  links: LinksType[]
  label?: string
}

export type NavigationLinksType = {
  [key: string]: LinksType
}

const Links: NavigationLinksType = {
  PrivacyPolicy: {
    title: "מדיניות הפרטיות",
    link: "#",
    blank: true,
  },
  TermsOfUse: {
    title: "תנאי שימוש",
    link: "#",
    blank: true,
  },
  Accessibility: { title: "הצהרת נגישות", link: "#", blank: true },
  MainPage: {
    title: "עמוד ראשי",
    link: "/",
  },
  AllModels: {
    title: "לכל ההצעות",
    link: "/search",
  },
  SmartAgent: {
    title: "הקפיצו לי הזדמנויות",
    link: "/contact-us",
  },
  TermsOfPurchase: {
    title: "תקנון הרכישה",
    link: "#",
    blank: true,
  },
  ContactUs: {
    title: "צרו קשר",
    link: "/contact-us",
  },
  FAQ: {
    title: "כל מה שחשוב לדעת",
    link: "/#faq",
  },
  BMW: {
    title: "BMW",
    link: "#",
  },
  NIO: {
    title: "NIO",
    link: "#",
  },
  MAZDA: {
    title: "MAZDA",
    link: "#",
  },
  MINICOOPER: {
    title: "MINI COOPER",
    link: "#",
  },
  FORD: {
    title: "FORD",
    link: "#",
  },
  BRONCO: {
    title: "BRONCO",
    link: "#",
  },
  Profile: {
    title: "צור קשר",
    link: "/contact-us",
  },
}

const SideDrawerMobile: NestedNavigationLinksType[] = [
  //nio header
  { title: Links.AllModels.title, links: [Links.AllModels] },
  {
    title: "חיפוש לפי מותג",
    links: [],
    label: "allModels",
  },
  { title: Links.SmartAgent.title, links: [Links.SmartAgent] },
  { title: Links.FAQ.title, links: [Links.FAQ] },
]

const HeaderLinkListDesktop: NestedNavigationLinksType[] = [...SideDrawerMobile]

const FooterLinks: LinksType[] = [
  Links.MainPage,
  Links.AllModels,
  Links.SmartAgent,
  Links.Profile,
  Links.PrivacyPolicy,
  Links.TermsOfUse,
  Links.Accessibility,
  Links.TermsOfPurchase,
]

export { FooterLinks, HeaderLinkListDesktop, Links, SideDrawerMobile }
